import React from 'react';
import LargeButton from './largeButton';
import smilingRemote from '../media/smilingRemote.webp'

const HeroHeader = () => {
    return (
        <div>
            <div className='container lg:mt-[-96px] mt-[-48px] lg:h-[90vh] sm:h-[85vh] h-[75vh] flex md:flex-row flex-col-reverse justify-center items-center mx-auto px-4'>
                <div className='flex flex-col justify-center gap-8'>
                    <h1 className='lg:text-6xl text-4xl font-bold md:pt-0 pt-6'>
                        Vos mini-stages en pilote automatique
                    </h1>
                    <p className='md:text-3xl sm:text-2xl text-xl font-semibold text-gray-400'>
                        Gagnez du temps et de l'énergie grâce à l'automatisation de vos mini-stages
                    </p>
                    <LargeButton
                        path={"/demo"}
                        text={"Essayer la Démo"}
                        customCss={`sm:mx-0 mx-auto`}
                    />
                </div>
                <div className='md:w-[100%] md:pl-12'>
                    <img src={smilingRemote} alt="person in front of computer at desk" className=' object-contain md:mt-0 sm:mt-24 rounded-3xl'/>
                </div>
            </div>
        </div>
    );
}

export default HeroHeader;
