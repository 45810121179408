import React, { useState } from 'react';
import yvette from '../media/Widerski-Sommevialle.jpeg';
import MingcuteLeftLine from './icons/leftArrow';
import MingcuteRightLine from './icons/rightArrow';
import { Link } from 'react-router-dom';

const testimonials = [
    {
        review: "\"Bac à stage une belle plateforme née il y a quelques années… Elle sait évoluer selon les besoins, très pratique d’utilisation et un gain de temps c’est clair. Bravo à Romain qui mérite qu’on lui fasse confiance\"",
        img: yvette,
        name: 'Yvette Widerski-Sommevialle',
        position: 'Principale adjointe',
        school: "Collège Claude Fauriel, Saint-Étienne",
    },
    {
        review: "\"Bac à stage une belle plateforme née il y a quelques années… Elle sait évoluer selon les besoins, très pratique d’utilisation et un gain de temps c’est clair. Bravo à Romain qui mérite qu’on lui fasse confiance\"",
        img: yvette,
        name: 'Yvette Widerski-Sommevialle',
        position: 'Principale adjointe',
        school: "Collège Claude Fauriel, Saint-Étienne",
    },
];

const Testimonial = () => {

    const [actualTesti, setActualTesti] = useState(0);

    const handleTestiDown = () => {
        setActualTesti((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1));
    };

    const handleTestiUp = () => {
        setActualTesti((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1));
    };

    return (
        <div className='container mx-auto flex flex-col items-center gap-24 pt-8 pb-16'>
            <div>
                <h2 className='lg:text-5xl sm:text-4xl text-3xl font-semibold my-8'>Témoignages</h2>
                <p className='text-xl font-medium'>Ils l'utilise au quotidien, et voici leur avis :</p>
            </div>
            <div className='flex flex-row md:gap-12 gap-4 px-4'>
                <Link className='relative top-16 pr-4' onClick={handleTestiDown}>
                    <MingcuteLeftLine />
                </Link>
                <div className=' flex flex-col md:w-[500px] gap-8'>
                    <p className='text-xl'>{testimonials[actualTesti].review}</p>
                    <div className='flex flex-row gap-8'>
                        <img className=' object-contain w-12 h-12 rounded-full shadow-md' src={testimonials[actualTesti].img} alt="book icon" />
                        <div className='flex flex-col gap-2'>
                            <p className='font-medium text-lg'>{testimonials[actualTesti].name}</p>
                            <p>{testimonials[actualTesti].position}</p>
                            <p>{testimonials[actualTesti].school}</p>
                        </div>
                    </div>
                </div>
                <Link className='relative top-16' onClick={handleTestiUp} >
                    <MingcuteRightLine />
                </Link>
            </div>
        </div>
    );
}

export default Testimonial;
