import React, { useState } from 'react';
import coding from '../media/coding.webp';
import photoRomain from '../media/photoRomain.webp';
import meeting from '../media/meeting.webp';
import worried from '../media/worried.webp';
import questions from '../media/questions.webp';
import hands from '../media/hands.webp';
import MingcuteLeftLine from './icons/leftArrow';
import MingcuteRightLine from './icons/rightArrow';

const History = () => {


    const [activeIndex, setActiveIndex] = useState(0);

    const history = [
        {
            title: "Un constat alarmant",
            date: 2013,
            text: "Un lundi matin, Patrick FAURE, professeur en lycée professionnel, se rend compte qu’il n’a aucune information sur les stages de ses élèves, il est inquiet.",
            image: worried,
        },
        {
            title: "Le début d’une solution",
            date: 2014,
            text: "Il commence à développer un logiciel pour gérer plus efficacement les stages en entreprise, et collabore avec des établissements.",
            image: coding,
        },
        {
            title: "Une demande forte",
            date: 2017,
            text: "À la demande des établissements, Patrick Faure décide de commencer le développement d'une solution pour la gestion des mini-stages.",
            image: questions,
        },
        {
            title: "Une montée en puissance",
            date: 2020,
            text: "Bacastages prend de l'ampleur. La gestion des mini-stages est en croissance constante, et plusieurs centaines d'établissements utilisent le logiciel.",
            image: meeting,
        },
        {
            title: "L'équipe s'agrandit",
            date: 2022,
            text: "Après plus de 9 ans passés en solitaire sur le projet, Romain SAINT-JEAN, un de ces anciens élèves, rejoint le projet, pour l'épauler dans la croissance.",
            image: photoRomain,
        },
        {
            title: "La passation",
            date: 2023,
            text: "Après plus de 10 ans de travail, Patrick décide de se retirer du projet. Romain prend les rênes, pour continuer à aider les lycées dans leur gestion des mini-stages.",
            image: hands,
        },
    ];

    const handlePreviousStory = () => {
        if(activeIndex > 0) {
            setActiveIndex((prev) => (prev - 1))
        };
    };

    const handleNextStory = () => {
        if(activeIndex < history.length - 1) {
            setActiveIndex((prev) => (prev + 1))
        };
    };

    return (
        <div className='w-full'>
            <div className='container mx-auto px-4'>
                <div className='grid lg:grid-cols-5 lg:h-[90vh] lg:pb-24 sm:pb-32 pb-16'>
                    <div className='flex flex-col gap-8 col-start-1 col-end-3 pr-8 lg:justify-center justify-start'>
                        <h2 className='lg:text-6xl sm:text-5xl text-4xl font-bold pt-4'>Notre histoire</h2>
                        <p className='md:text-2xl text-xl font-medium'>Depuis 2013, nous n'avons cessé d'accompagner des établissements dans leur gestion de leurs stages et mini-stages.</p>
                    </div>
                    <div className='col-span-3 lg:pl-4 lg:pt-0 pt-16'>
                        <div className='flex flex-row justify-between h-full items-center'>
                            <div className='w-12 h-fit relative top-[50px] lg:block hidden'>
                                { activeIndex !== 0 &&
                                    <MingcuteLeftLine className='w-full h-fit bg-[#0751FC] text-white rounded-full' onClick={handlePreviousStory} />
                                }
                            </div>
                            <div className='flex flex-col lg:px-4 lg:w-[550px] w-full'>
                                <div className='relative h-[300px]'>
                                    <div className='absolute top-1/2 transform -translate-y-1/2 left-0 cursor-pointer lg:hidden'>
                                        { activeIndex !== 0 &&
                                            <MingcuteLeftLine className='w-12 h-fit text-white bg-[#0751FC] rounded-xl' onClick={handlePreviousStory} />
                                        }
                                    </div>
                                    <img className={`w-full h-full object-cover rounded-2xl`} src={history[activeIndex].image} alt="" />
                                    <div className='absolute top-1/2 transform -translate-y-1/2 right-0 cursor-pointer lg:hidden'>
                                        { activeIndex !== history.length - 1 &&
                                            <MingcuteRightLine className='w-12 h-fit text-white bg-[#0751FC] rounded-xl' onClick={handleNextStory} />
                                        }
                                    </div>
                                </div>
                                <h4 className='font-medium sm:text-2xl text-xl pt-6'>
                                    <span className='text-[#0751FC]'>{history[activeIndex].date} - </span>
                                    {history[activeIndex].title}
                                </h4>
                                <p className='mt-6 mb-6 lg:text-lg'>{history[activeIndex].text}</p>
                            </div>
                            <div className='w-12 h-fit relative top-[50px] lg:block hidden'>
                                { activeIndex !== history.length - 1 &&
                                    <MingcuteRightLine className='w-full h-fit bg-[#0751FC] text-white rounded-full' onClick={handleNextStory} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default History;
