import React from 'react';
import clock from '../media/clock.webp'
import link from '../media/link.webp'
import key from '../media/key.webp'

const Benefits = () => {
    return (
        <div className='container mx-auto flex flex-col items-center lg:py-16 pt-16 px-4 gap-48'>
            <div>
                <h2 className='lg:text-5xl sm:text-4xl text-3xl font-semibold text-center my-8'>Avantages</h2>
                <p className='lg:text-xl text-lg font-medium'>Bacastages, c'est bien plus qu'un outil, il est votre allié</p>
            </div>
            <div className='grid lg:grid-cols-3 gap-28 lg:gap-16'>
                <div className='rounded-xl px-8 pb-16 shadow-lg'>
                    <img className='w-48 mx-auto mt-[-118px] rotate-22' src={clock} alt="clock 3D icon" />
                    <h4 className='font-semibold sm:text-3xl text-2xl my-8'>Un gain de temps</h4>
                    <div className='flex flex-col gap-8'>
                        <p>
                            • Préparer votre campagne avant tout le monde, pour un démarrage serein
                        </p>
                        <p>
                            • L’inscription est prise, et la convention envoyée automatiquement
                        </p>
                        <p>
                            • Configuration de votre établissement en moins de 2 minutes
                        </p>
                    </div>
                </div>
                <div className='rounded-xl px-8 pb-16 shadow-lg md:mt-0 mt-8'>
                    <img className='w-48 mx-auto mt-[-118px]' src={key} alt="link 3D icon" />
                    <h4 className='font-semibold sm:text-3xl text-2xl my-8'>Une sécurité renforcée</h4>
                    <div className='flex flex-col gap-8'>
                        <p>
                            • Gardez le contrôle sur les données que vous partagez
                        </p>
                        <p>
                            • Seules les données essentielles sont collectées
                        </p>
                        <p>
                            • Application est conforme aux normes strictes du RGPD
                        </p>
                    </div>
                </div>
                <div className='rounded-xl px-8 pb-16 shadow-lg md:mt-0 mt-8'>
                    <img className='w-48 mx-auto mt-[-118px]' src={link} alt="link 3D icon" />
                    <h4 className='font-semibold sm:text-3xl text-2xl my-8'>Un seul et même outil</h4>
                    <div className='flex flex-col gap-8'>
                        <p>
                            • Tout est centralisé sur un seul outil
                        </p>
                        <p>
                            • Accéder aux mini-stages de toute la France avec un seul identifiant
                        </p>
                        <p>
                            • Plateforme accessible à tous : professeur, parents, personnel de direction…
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Benefits;
