import React from 'react';

const LegalNotice = () => {
    return (
        <div className='container mx-auto flex flex-col px-4 py-16 '>
            <h1 className='md:text-6xl sm:text-4xl text-2xl font-bold mb-12'>
                Mentions Légales
            </h1>
            <p className='text-lg'>
                Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Lussolva.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
            </p>

            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Edition du site</h2>

            <p className='text-lg mb-6'>Le présent site, accessible à l’URL https://lussolva.fr (le « Site »), est édité par :</p>

            <p className='text-lg'>Romain SAINT-JEAN, résidant 188  Chemin de la Gimond 69610 GRÉZIEU LE MARCHÉ, de nationalité Française (France), né(e) le 18/03/2002,  inscrite au R.C.S. de LYON sous le numéro 912706637,</p>

            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Hébergement</h2>

            <p className='text-lg'>Le Site est hébergé par la société Wevox SARL, situé 3 rue de Franche Comté 50103 Cherbourg en cotentin, (contact téléphonique ou email : +33972550850).</p>
            
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Directeur de publication</h2>

            <p className='text-lg'>Le Directeur de la publication du Site est Romain SAINT-JEAN.</p>

            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Nous contacter</h2>

            <p className='text-lg mb-6'>Par téléphone : +33783639417</p>

            <p className='text-lg mb-6'>Par email : romain.sj.pro@gmail.com</p>

            <p className='text-lg'>Par courrier : 188  Chemin de la Gimond, 69610 GRÉZIEU LE MARCHÉ</p>

            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Données personnelles</h2>

            <p className='text-lg'>Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, disponible depuis la section "Charte de Protection des Données Personnelles", conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).</p>
        </div>
    );
}

export default LegalNotice;
