import React from 'react';

const TermsofUse = () => {
    return (
        <div className='container mx-auto flex flex-col px-4 py-16 '>
            
            <h1 className='md:text-6xl sm:text-4xl text-2xl font-bold mb-12'>
                Conditions Générales d'Utilisation  
            </h1>

            <p className='text-lg mb-6'>
                En vigueur au 14/04/2024
            </p>
            <p className='text-lg mb-6'>
                Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par MONSIEUR ROMAIN SAINT-JEAN E.I. et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».
            </p>
            <p className='text-lg mb-6'>
                Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
            </p>
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Article 1 : Les mentions légales</h2>
            <p className='text-lg mb-6'>L’édition et la direction de la publication du site lussolva.fr est assurée par Romain SAINT-JEAN, domicilié 188 Chemin de la Gimond.</p>
            <p className='text-lg mb-6'>Numéro de téléphone est 0783639417</p>
            <p className='text-lg mb-6'>Adresse e-mail romain.sj.pro@gmail.com.</p>
            <p className='text-lg mb-6'>La personne est assujetie au RCS avec le numéro d'inscription Lyon A 912 706 637 et son numéro de TVA intracommunautaire est le FR43912706637</p>
            <p className='text-lg mb-6'>L'hébergeur du site lussolva.fr est la société Wevox SARL, dont le siège social est situé au 3 rue de Franche Comté 50103 Cherbourg en cotentin, avec le numéro de téléphone : 33972550850.</p>
            
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Article 2 : Accès au Site</h2>

            <p className='text-lg mb-6'>Le site lussolva.fr permet à l'Utilisateur un accès gratuit aux services suivants :</p>
            <p className='text-lg mb-6'>Le site internet propose les services suivants :</p>
            <p className='text-lg mb-6'>Site vitrine de l'outil Bacastages.fr, la page d'accueil comprend plusieurs sections pour présenter le produit, la page Démo permet de faire une demande d'accès pour essayer la démo, la page devis permet de faire une demande de devis, la page à propos résume l'histoire de l'entreprise, la page contact regroupe un formulaire de contact et différentes informations pour prendre contact avec nous Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.</p>
            
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Article 3 : Collecte des données</h2>

            <p className='text-lg mb-6'>Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.</p>
            <p className='text-lg mb-6'>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit :</p>
            <p className='text-lg mb-6'>· par mail à l'adresse email romain.sj.pro@gmail.com</p>
            
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Article 4 : Propriété intellectuelle</h2>

            <p className='text-lg mb-6'>Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son...) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.</p>
            <p className='text-lg mb-6'>L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.</p>
            <p className='text-lg mb-6'>Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.</p>
            <p className='text-lg mb-6'>Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.</p>
            
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Article 5 : Responsabilité</h2>
            <p className='text-lg mb-6'>Les sources des informations diffusées sur le site lussolva.fr sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.</p>
            <p className='text-lg mb-6'>Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site lussolva.fr ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.</p>
            <p className='text-lg mb-6'>Le site lussolva.fr ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site.</p>
            <p className='text-lg mb-6'>La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.</p>
            
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Article 6 : Liens hypertextes</h2>
            <p className='text-lg mb-6'>Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site lussolva.fr. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.</p>

            
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Article 7 : Cookies</h2>
            <p className='text-lg mb-6'>L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.</p>
            <p className='text-lg mb-6'>Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site lussolva.fr. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.</p>
            <p className='text-lg mb-6'>L’information contenue dans les cookies est utilisée pour améliorer le site lussolva.fr.</p>
            <p className='text-lg mb-6'>En naviguant sur le site, L’Utilisateur les accepte.</p>
            <p className='text-lg mb-6'>L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.</p>
            
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Article 8 : Droit applicable et juridiction compétente</h2>
            <p className='text-lg mb-6'>La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.</p>
            <p className='text-lg mb-6'>Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.</p>
            <p className='text-lg mb-6'>CGU réalisées sur http://legalplace.fr/</p>
        </div>
    );
}

export default TermsofUse;
