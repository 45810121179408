import React from 'react';
import womanWalking from '../media/womanWalking.webp';
import PhLeaf from './icons/leaf';
import OcticonPeople24 from './icons/peoples';
import MageStarsA from './icons/stars';

const Values = () => {

    return (
        <div className='container mx-auto px-4 lg:py-16'>
            <div className='grid lg:grid-cols-2 lg:gap-16 gap-12 items-center'>
                <div className='lg:w-[90%] w-full lg:h-auto h-[400px]'>
                    <img className='object-cover w-full h-full lg:mx-0 mx-auto rounded-3xl' src={womanWalking} alt="" />
                </div>
                <div className='flex flex-col'>
                    <h2 className='lg:text-5xl sm:text-4xl text-3xl font-semibold pb-8'>Nos valeurs</h2>
                    <p className='lg:text-xl text-lg font-medium'>Notre passion pour le service client et la satisfaction !</p>
                    <div className='flex flex-col gap-16 lg:mt-16 mt-12'>
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-row items-center gap-2'>
                                <PhLeaf />
                                <h3 className='font-medium lg:text-3xl text-2xl'>Simplicité</h3>
                            </div>
                            <p className='font-light text-lg'>Nous croyons en la simplicité, que ce soit dans nos échanges, mais aussi dans notre travail, pour qu’il puisse être compris de tous.</p>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-row items-center gap-2'>
                                <OcticonPeople24 />
                                <h3 className='font-medium lg:text-3xl text-2xl'>Collaboration</h3>
                            </div>
                            <p className='font-light text-lg'>Nous voyons la collaboration comme un pilier, qui permet de nous faire grandir et avancer plus vite.</p>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-row items-center gap-2'>
                                <MageStarsA />
                                <h3 className='font-medium lg:text-3xl text-2xl'>Excellence</h3>
                            </div>
                            <p className='font-light text-lg'>L’excellence est au corps de notre identité, pour vous offrir une expérience toujours plus agréable et mémorable.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Values;
