import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='container mx-auto flex flex-col px-4 py-16 '>
            <h1 className='md:text-6xl sm:text-4xl text-2xl font-bold mb-12'>
                Politique de confidentialité  
            </h1>
            <p className='text-lg'>
                Site : Lussolva.fr
            </p>
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>Article 1 : Préambule</h2>
            <p className='text-lg mb-6'>La présente politique de confidentialité a pour but d’informer les utilisateurs du site :  </p>
            <ul className='text-lg mb-8 pl-4'>
                <li className='mb-2'>•	Sur la manière dont sont collectées leurs données personnelles. Sont considérées comme des données personnelles, toute information permettant d’identifier un utilisateur. A ce titre, il peut s’agir : de ses noms et prénoms, de son âge, de son adresse postale ou email, de sa localisation ou encore de son adresse IP (liste non-exhaustive) ; </li>
                <li className='mb-2'>•	Sur les droits dont ils disposent concernant ces données ;  </li>
                <li className='mb-2'>•	Sur la personne responsable du traitement des données à caractère personnel collectées et traitées ;</li>
                <li className='mb-2'>•	Sur les destinataires de ces données personnelles ;</li>
                <li className='mb-2'>•	Sur la politique du site en matière de cookies.</li>
            </ul>
            <p className='text-lg mb-6'>Cette politique complète les mentions légales et les Conditions Générales d’Utilisation consultables par les utilisateurs à l’adresse suivante </p>
            <p className='text-lg mb-6'><strong>Mentions légales : </strong> https://lussolva.fr/legal</p>
            <p className='text-lg mb-6'><strong>Conditions Générales d’Utilisation : </strong> https://lussolva.fr/termsofuse</p>



            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>ARTICLE 2 : PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES DONNÉES PERSONNELLES</h2>
            <p className='text-lg mb-6'>Conformément à l’article 5 du Règlement européen 2016/679, les données à caractère personnel sont :</p>
            <ul className='text-lg mb-8 pl-4'>
                <li className='mb-2'>•	Traitées de manière licite, loyale et transparente au regard de la personne concernée  ; </li>
                <li className='mb-2'>•	Collectées pour des finalités déterminées (cf. Article 3.1 des présentes), explicites et légitimes, et ne pas être traitées ultérieurement d'une manière incompatible avec ces finalités ; </li>
                <li className='mb-2'>•	Adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées ; </li>
                <li className='mb-2'>•	Exactes et, si nécessaire, tenues à jour. Toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder ;  </li>
                <li className='mb-2'>•	Conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées ; </li>
                <li className='mb-2'>•	Traitées de façon à garantir une sécurité appropriée des données collectées, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées.  </li>
            </ul>
            <p className='text-lg mb-6'>Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie :</p>
            <ul className='text-lg mb-6 pl-4'>
                <li className='mb-2'>•	La personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques ;</li>
                <li className='mb-2'>•	Le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie ou à l'exécution de mesures précontractuelles prises à la demande de celle-ci ; </li>
                <li className='mb-2'>•	Le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du traitement est soumis ; </li>
                <li className='mb-2'>•	Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ; </li>
                <li className='mb-2'>•	Le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont est investi le responsable du traitement ; </li>
                <li className='mb-2'>•	Le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant.</li>
            </ul>



            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</h2>
            
            
            <h3 className='font-bold md:text-2xl text-lg my-4'>Article 3.1 : Données collectées</h3>
            <p className='text-lg mb-6 font-medium'>Formulaire de contact :</p>
            <ul className='text-lg mb-8 pl-4'>
                <li className='mb-2'>•	Nom</li>
                <li className='mb-2'>•	Email</li>
                <li className='mb-2'>•	Nom de l’établissement</li>
                <li className='mb-2'>•	Message</li>
            </ul>

            <p className='text-lg mb-6 font-medium'>Formulaire de demande de devis :</p>
            <ul className='text-lg mb-8 pl-4'>
                <li className='mb-2'>•	Nom</li>
                <li className='mb-2'>•	Email</li>
                <li className='mb-2'>•	Code UAI de l’établissement</li>
            </ul>

            <p className='text-lg mb-6 font-medium'>Formulaire de demande de démo :</p>
            <ul className='text-lg mb-8 pl-4'>
                <li className='mb-2'>•	Nom</li>
                <li className='mb-2'>•	Email</li>
                <li className='mb-2'>•	Code UAI de l’établissement</li>
            </ul>

            <p className='text-lg mb-6'>La collecte et le traitement de ces données répond à la (aux) finalité(s) suivante(s) :  </p>
            <p className='text-lg mb-6 font-medium'>Formulaire de contact :</p>
            <p className='text-lg mb-6'>Vous répondre et faire un suivi des demandes du client.</p>
            <p className='text-lg mb-6 font-medium'>Formulaire de demande de devis :</p>
            <p className='text-lg mb-6'>Traiter la demande et vous envoyez le devis. </p>
            <p className='text-lg mb-6 font-medium'>Formulaire de demande de démo :</p>
            <p className='text-lg mb-6'>Traiter la demande, vous envoyer les codes d’accès.</p>


            <h3 className='font-bold md:text-2xl text-lg my-4'>Article 3.2 : Mode de collecte des données </h3>
            <p className='text-lg mb-6'>Lorsque vous utilisez notre site, aucune donnée n’est collectée automatiquement.</p>
            <p className='text-lg mb-6'>D’autres données personnelles sont collectées lorsque vous effectuez les opérations suivantes sur la plateforme :</p>
            <p className='text-lg mb-6 font-medium'>Formulaire de contact :</p>
            <ul className='text-lg mb-8 pl-4'>
                <li className='mb-2'>•	Nom</li>
                <li className='mb-2'>•	Email</li>
                <li className='mb-2'>•	Nom de l’établissement</li>
                <li className='mb-2'>•	Message</li>
            </ul>
            <p className='text-lg mb-6'>But : Vous répondre et faire un suivi des demandes du client.</p>

            <p className='text-lg mb-6 font-medium'>Formulaire de demande de devis :</p>
            <ul className='text-lg mb-8 pl-4'>
                <li className='mb-2'>•	Nom</li>
                <li className='mb-2'>•	Email</li>
                <li className='mb-2'>•	Code UAI de l’établissement</li>
            </ul>
            <p className='text-lg mb-6'>But : Traiter la demande et vous envoyez le devis. </p>

            <p className='text-lg mb-6'>Formulaire de demande de démo :</p>
            <ul className='text-lg mb-8 pl-4'>
                <li className='mb-2'>•	Nom</li>
                <li className='mb-2'>•	Email</li>
                <li className='mb-2'>•	Code UAI de l’établissement</li>
            </ul>
            <p className='text-lg mb-6'>But : Traiter la demande, vous envoyer les codes d’accès.</p>

            <p className='text-lg mb-6'>Elles sont conservées par le responsable du traitement dans des conditions raisonnables de sécurité, pour une durée de : 12 mois.</p>
            <p className='text-lg mb-6'>La société est susceptible de conserver certaines données à caractère personnel au-delà des délais annoncés ci-dessus afin de remplir ses obligations légales ou réglementaires.</p>
            <h3 className='font-bold md:text-2xl text-lg my-4'>Article 3.3 : Hébergement des données</h3>
            <p className='text-lg mb-6'>Le site Lussolva.fr est hébergé par :  </p>
            <p className='text-lg mb-6'>MONSIEUR ROMAIN SAINT-JEAN E.I.</p>
            <p className='text-lg mb-6'>188 Chemin de la Gimond, 69610, Grézieu le Marché</p>
            <p className='text-lg mb-6'>Téléphone : 0783639417</p>
            <p className='text-lg mb-6'>Email : romain.sj.pro@gmail.com</p>



            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA PROTECTION DES DONNÉES</h2>
            
            
            <h3 className='font-bold md:text-2xl text-lg my-4'>Article 4.1 : Le responsable du traitement des données</h3>
            <p className='text-lg mb-6'>Les données à caractère personnelles sont collectées par MONSIEUR ROMAIN SAINT-JEAN E.I, dont le numéro d’immatriculation est le RCS Lyon A 912 706 637.  </p>
            <p className='text-lg mb-6'>Le responsable du traitement des données à caractère personnel peut être contacté de la manière suivante :  </p>
            <p className='text-lg mb-6'>Par courrier à l’adresse :  188 Chemin de la Gimond, 69610, Grézieu le Marché;  </p>
            <p className='text-lg mb-6'>Par téléphone, au 0783639417 ;  </p>
            <p className='text-lg mb-6'>Par mail : romain.sj.pro@gmail.com.  </p>
            
            
            <h3 className='font-bold md:text-2xl text-lg my-4'>Article 4.2 : Le délégué à la protection des données</h3>
            <p className='text-lg mb-6'>Le délégué à la protection des données de l’entreprise ou du responsable est : </p>
            <p className='text-lg mb-6'>Romain SAINT-JEAN, 188 Chemin de la Gimond, 69610, Grézieu le Marché, 0783639417, romain.sj.pro@gmail.com</p>
            <p className='text-lg mb-6'>Si vous estimez, après nous avoir contactés, que vos droits “Informatique et Libertés”, ne sont pas respectés, vous pouvez adresser une information à la CNIL. </p>
            
            
            
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>ARTICLE 5 : LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DES DONNÉES</h2>
            <p className='text-lg mb-6'>Tout utilisateur concerné par le traitement de ses données personnelles peut se prévaloir des droits suivants, en application du règlement européen 2016/679 et de la Loi Informatique et Liberté (Loi 78-17 du 6 janvier 1978) :   </p>
            <ul className='text-lg mb-8 pl-4'>
                <li className='mb-2'>•	Droit d’accès, de rectification et droit à l’effacement des données (posés respectivement aux articles 15, 16 et 17 du RGPD) ;</li>
                <li className='mb-2'>•	Droit à la portabilité des données (article 20 du RGPD) ;</li>
                <li className='mb-2'>•	Droit à la limitation (article 18 du RGPD) et à l’opposition du traitement des données (article 21 du RGPD) ;</li>
                <li className='mb-2'>•	Droit de ne pas faire l’objet d’une décision fondée exclusivement sur un procédé automatisé ;</li>
                <li className='mb-2'>•	Droit de déterminer le sort des données après la mort ;</li>
                <li className='mb-2'>•	Droit de saisir l’autorité de contrôle compétente (article 77 du RGPD).</li>
            </ul>  
            <p className='text-lg mb-6'>Pour exercer vos droits, veuillez adresser votre courrier à [Nom et adresse de l’entreprise] ou par mail à [Insérer l’adresse e-mail du Délégué à la protection des données] </p>
            <p className='text-lg mb-6'>Afin que le responsable du traitement des données puisse faire droit à sa demande, l’utilisateur peut être tenu de lui communiquer certaines informations telles que : ses noms et prénoms, son adresse e-mail ainsi que son numéro de compte, d’espace personnel ou d’abonné.  </p>
            <p className='text-lg mb-6'>Consultez le site cnil.fr pour plus d’informations sur vos droits. </p>
            
            
            
            <h2 className='md:text-4xl sm:text-2xl text-lg font-semibold my-8'>ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
            <p className='text-lg mb-6'>L’éditeur du site Lussolva.fr se réserve le droit de pouvoir modifier la présente Politique à tout moment afin d’assurer aux utilisateurs du site sa conformité avec le droit en vigueur.  </p>
            <p className='text-lg mb-6'>Les éventuelles modifications ne sauraient avoir d’incidence sur les achats antérieurement effectués sur le site, lesquels restent soumis à la Politique en vigueur au moment de l’achat et telle qu’acceptée par l’utilisateur lors de la validation de l’achat.  </p>
            <p className='text-lg mb-6'>L’utilisateur est invité à prendre connaissance de cette Politique à chaque fois qu’il utilise nos services, sans qu’il soit nécessaire de l’en prévenir formellement.  </p>
            <p className='text-lg mb-6'>La présente politique, éditée le 14 avr. 2024, a été mise à jour le 14 avr. 2024.</p>


        </div>
    );
}

export default PrivacyPolicy;
