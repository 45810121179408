import React from 'react';
import History from '../Components/history';
import Values from '../Components/values';
import MissionAndVision from '../Components/missionAndVision';
import ContactCta from '../Components/contactCta';
import AboutPhotosId from '../Components/aboutPhotosId';

const About = () => {

    return (
        <>
            <History />
            <Values />
            <MissionAndVision />
            <ContactCta />
            <AboutPhotosId />
        </>
    );
}

export default About;
