import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import linkedin from '../media/linkedin.webp'
import book from '../media/book.webp'
import IcRoundPhone from '../Components/icons/phone';
import MaterialSymbolsMailOutline from '../Components/icons/email';
import ky from 'ky';
import Popup from '../Components/popup';
import Loader from '../Components/loader';

const Contact = () => {

    const [error, setError] = useState(null);
    const [validateCondition, setValidateCondition] = useState(false);
    const [messageSentPopup, setMessageSentPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        school: '',
        message: '',
    });

    const validationMessage = {
        name: "Veuillez saisir votre nom.",
        email: "Veuillez saisir votre adresse e-mail.",
        school: "Veuillez saisir le nom de votre établissement.",
        message: "Veuillez saisir votre message."
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
        setError(null)
    };

    const handleCheckboxChange = (event) => {
        setValidateCondition(event.target.checked);
    };

    const handleServerError = async (error) => {
        if (error.response) {
          try {
            const errorData = await error.response.json();
            const errorMessage = errorData.message;
            setError(errorMessage);
          } catch (jsonError) {
            console.error('Impossible de parser la réponse d\'erreur en JSON:', jsonError);
            setError('Une erreur s\'est produite. Veuillez réessayer plus tard.');
          }
        } else {
          setError('Une erreur s\'est produite. Veuillez réessayer plus tard.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        for (const field in formData) {
            if (!formData[field].trim()) {
                setError(validationMessage[field]);
                return;
            }
        };

        if (!validateCondition) {
            setError("Pour soumettre votre message, veuillez d'abord accepter nos conditions d'utilisation. Merci de votre compréhension !")
            return;
        };

        try {
            let url;
            if (process.env.NODE_ENV === 'production'){
                url = "https://api.lussolva.fr"
            } else {
                url = 'http://localhost:4500'
            };
            
            const res = await ky.post(`${url}/email/contact`, {json: formData}).json();
            setIsLoading(true);

            if(res) {
                setIsLoading(false)
                setMessageSentPopup(true);
            };

            setFormData({
                name: '',
                email: '',
                school: '',
                message: '',
            });
            setValidateCondition(false);
            setError(null);

            return ;

        } catch (error) {
            setIsLoading(false);
            handleServerError(error);
        };
    };

    const handleClosePopup = () => {
        setMessageSentPopup(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setMessageSentPopup(false)
        }, 10000);

    }, [messageSentPopup])

    return (
        <>
            { messageSentPopup &&
                <Popup 
                    isOpen={messageSentPopup}
                    message={`Votre message a été envoyé avec succès !`}
                    closePopup={handleClosePopup}
                />
            }
            <div className='container lg:h-[90vh] mx-auto px-4'>
                <div className='grid lg:grid-cols-8 lg:gap-24 items-end'>
                    <form onSubmit={handleSubmit} className='flex flex-col lg:col-span-5 justify-between w-full'>
                        <h1 className='lg:text-6xl sm:text-5xl text-4xl font-bold lg:pt-16 pt-4'>Contactez-nous</h1>
                        <p className='lg:text-xl lg:mt-8 mt-4'>Nous sommes là pour répondre à toutes vos questions.</p>
                        <div className='flex flex-col lg:gap-12 gap-8 lg:mt-16 sm:mt-8 mt-6'>
                            <div className='flex lg:flex-row flex-col gap-8'>
                                <input value={formData.name} onChange={handleChange} name='name' type="text" className='w-full lg:text-2xl text-xl px-2 border-b-2 pb-2 border-black focus:border-[#0751FC] outline-none' placeholder='Nom' />
                                <input value={formData.email} onChange={handleChange} name='email' type="email" className='w-full lg:text-2xl text-xl px-2 border-b-2 pb-2 border-black focus:border-[#0751FC] outline-none' placeholder='exemple@this.com' />
                            </div>
                            <input value={formData.school} onChange={handleChange} name='school' type="text" placeholder='Établissement' className='w-full lg:text-2xl text-xl px-2 border-b-2 pb-2 border-black focus:border-[#0751FC] outline-none'/>
                            <textarea value={formData.message} onChange={handleChange} name="message" rows={"3"} className='w-full lg:text-2xl text-xl border-b-2 px-2 border-black focus:border-[#0751FC] outline-none' placeholder='Message'></textarea>
                        </div>
                        <div className='flex flex-row pt-4'>
                            <input type="checkbox" onChange={handleCheckboxChange} checked={validateCondition} />
                            <p className='pl-2'>J'ai lu et j'accepte les
                                <Link to={"/privacy"} className='text-[#0751FC] underline'> conditions</Link>
                            </p>
                        </div>
                        { error &&
                            <p className='text-xl pt-2 text-red-500'>{error}</p>
                        }
                        { isLoading ?
                            <div className='h-[60px] px-8 py-4 mt-8'>
                                <Loader />
                            </div> 
                        :
                            <button type='submit' className={`bg-[#0751FC] text-white w-fit font-medium text-xl rounded-lg  px-8 py-4 mt-8`} >Envoyer</button>
                        }
                    </form>
                    <div className='lg:col-span-3 relative lg:mt-0 mt-32'>
                        <div className='flex flex-col gap-16 px-12 pb-12 pt-24 rounded-2xl shadow-xl'>
                            <div className='flex flex-col gap-6'>
                                <h3 className='font-semibold lg:text-3xl text-2xl'>Téléphone</h3>
                                <div className='flex flex-row gap-2'>
                                    <IcRoundPhone />
                                    <p className='lg:text-xl text-lg'>07 83 63 94 17</p>
                                </div>
                            </div>
                            <div className='flex flex-col gap-6'>
                                <h3 className='font-semibold lg:text-3xl text-2xl'>Email</h3>
                                <div className='flex flex-row gap-2'>
                                    <MaterialSymbolsMailOutline />
                                    <p className='lg:text-xl text-lg'>contact@lussolva.fr</p>
                                </div>
                            </div>
                            <div className='flex flex-col gap-6'>
                                <h3 className='font-semibold lg:text-3xl text-2xl'>Réseaux</h3>
                                <Link className='w-8' to={'https://linkedin.com/in/romain-saint-jean'}>
                                    <img className='' src={linkedin} alt="" />
                                </Link>
                            </div>
                            <img className='w-32 absolute left-1/2 top-[-64px] transform -translate-x-1/2 rotate-12' src={book} alt=""  />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;
