import React, { useEffect, useState } from 'react';
import ky from 'ky';
import Loader from './loader';
import { Link } from 'react-router-dom';
import MaterialSymbolsSearch from './icons/search';
import Popup from './popup';
import ship from '../media/ship.webp';

const FormDemo = () => {

    const [error, setError] = useState(null);
    const [validateCondition, setValidateCondition] = useState(false);
    const [messageSentPopup, setMessageSentPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        schoolUai: '',
    });

    const validationMessage = {
        name: "Veuillez saisir votre nom.",
        email: "Veuillez saisir votre adresse e-mail.",
        schoolUai: "Veuillez saisir le code UAI de votre établissement.",
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
        setError(null)
    };

    const handleCheckboxChange = (event) => {
        setValidateCondition(event.target.checked);
    };

    const handleServerError = async (error) => {
        if (error.response) {
          try {
            const errorData = await error.response.json();
            const errorMessage = errorData.message;
            setError(errorMessage);
          } catch (jsonError) {
            console.error('Impossible de parser la réponse d\'erreur en JSON:', jsonError);
            setError('Une erreur s\'est produite. Veuillez réessayer plus tard.');
          }
        } else {
          setError('Une erreur s\'est produite. Veuillez réessayer plus tard.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        for (const field in formData) {
            if (!formData[field].trim()) {
                setError(validationMessage[field]);
                return;
            }
        };

        if (!validateCondition) {
            setError("Pour soumettre votre demande, veuillez d'abord accepter nos conditions d'utilisation. Merci de votre compréhension !")
            return;
        };

        try {
            let url;
            if (process.env.NODE_ENV === 'production'){
                url = "https://api.lussolva.fr"
            } else {
                url = 'http://localhost:4500'
            };
            
            const res = await ky.post(`${url}/email/demo`, {json: formData}).json();
            setIsLoading(true);
            
            if(res) {
                setIsLoading(false)
                setMessageSentPopup(true);
            };

            setFormData({
                name: '',
                email: '',
                schoolUai: '',
            });
            setValidateCondition(false);
            setError(null);

            return;

        } catch (error) {
            setIsLoading(false)
            handleServerError(error)

        }
    };

    const handleClosePopup = () => {
        setMessageSentPopup(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setMessageSentPopup(false)
        }, 10000);

    }, [messageSentPopup])


    return (
        <>
            { messageSentPopup &&
                <Popup 
                    isOpen={messageSentPopup}
                    message={`Votre demande a été envoyé avec succès !`}
                    closePopup={handleClosePopup}
                />
            }
            <div className='container  lg:h-[85vh] h-full mx-auto px-4'>
                <div className='grid md:grid-cols-2 h-full lg:gap-16 gap-4'>
                <div className='flex flex-col gap-8 justify-center'>
                        <h1 className='lg:text-6xl sm:text-5xl text-4xl font-bold '>
                            Découvrez par vous-même !
                        </h1>
                        <p className='md:text-2xl text-xl font-medium'>
                        Commencez gratuitement et explorez toutes les fonctionnalités disponibles dès maintenant.
                        </p>
                        <div className='w-full md:block hidden'>
                            <img className='w-[96px] rotate-45 mx-auto' src={ship} alt="spatial ship 3d icon" />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className=' my-auto'>
                        <div className='flex flex-col lg:gap-12 sm:gap-10 gap-8 lg:mt-0 mt-8'>
                            <input value={formData.name} onChange={handleChange} name='name' type="text" className='w-full lg:text-2xl text-xl px-2 border-b-2 pb-2 border-black focus:border-[#0751FC] outline-none' placeholder='Nom' />
                            <input value={formData.email} onChange={handleChange} name='email' type="email" className='w-full lg:text-2xl text-xl px-2 border-b-2 pb-2 border-black focus:border-[#0751FC] outline-none' placeholder='Email' />
                            <div className='relative w-full'>
                                <input value={formData.schoolUai} onChange={handleChange} name='schoolUai' type="text" className='w-full lg:text-2xl text-xl outline-none px-2 pb-2 border-b-2 border-black focus:border-[#0751FC]' placeholder='Code UAI' />
                                <div className='absolute top-0 right-0 flex items-center pr-2 text-[#9ba3af]'>
                                    <MaterialSymbolsSearch />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row pt-4'>
                            <input type="checkbox" onChange={handleCheckboxChange} checked={validateCondition} />
                            <p className='pl-2'>J'ai lu et j'accepte les
                                <Link to={"/privacy"} className='text-[#0751FC] underline'> conditions</Link>
                            </p>
                        </div>
                        { error &&
                            <p className='text-xl pt-2 text-red-500'>{error}</p>
                        }
                        { isLoading ?
                            <div className='h-[60px] px-8 py-4 lg:mt-8 mt-12'>
                                <Loader />
                            </div> 
                        :
                            <button type='submit' className={`bg-[#0751FC] text-white w-fit font-medium text-xl rounded-lg  px-8 py-4 lg:mt-8 mt-12`} >Demander mon accès</button>
                        }
                    </form>
                </div>
            </div>
        </>
    );
}

export default FormDemo;
