import React from 'react';

const Popup = ({ isOpen, message, closePopup }) => {

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      <div className="bg-white p-6 rounded-2xl shadow-lg z-10 relative w-[450px]">
        <button
          className="text-gray-500 hover:text-red-500 focus:outline-none absolute top-0 right-0 mt-5 mr-5"
          onClick={closePopup}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
        </button>
        <p className="text-lg font-medium p-6">{message}</p>
      </div>
    </div>
  );
};

export default Popup;
