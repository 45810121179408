import React from 'react';
import MaxiCard from './cards/maxiCard';
import PlusCard from './cards/plusCard';
import ClassiqueCard from './cards/classiqueCard';
import FreeCard from './freeCard';

const Pricing = () => {
    return (
        <div className='container mx-auto flex flex-col px-4 lg:pt-20 sm:pt-12 pt-8 sm:pb-16 pb-6 gap-24 items-center'>
            <div>
                <h2 className='lg:text-5xl sm:text-4xl text-3xl text-center font-semibold mb-8'>Tarifs</h2>
                <p className='lg:text-xl text-lg text-center font-medium'>Découvrez nos tarifs avantageux pour chaque niveau d'utilisation.</p>
            </div>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-5'>
                <MaxiCard />
                <PlusCard />
                <ClassiqueCard />
                <FreeCard />
            </div>
        </div>
    );
}

export default Pricing;
