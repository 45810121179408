import React from 'react';

import OcticonGear24 from './icons/gear';
import MdiPaperOutline from './icons/paper';
import ClarityNotificationLine from './icons/notif';
import QuillCalendar from './icons/calendar';
import LucideLink from './icons/link';
import PhExport from './icons/export';
import NimbusStats from './icons/stats';
import IonEyeOutline from './icons/eye';


const Features = () => {
    return (
        <div className='container mx-auto px-4 lg:py-8 sm:pt-12 pt-8'>
            <div>
                <h2 className='lg:text-5xl sm:text-4xl text-3xl font-semibold my-8'>Fonctionnalités</h2>
                <p className='lg:text-xl text-lg font-medium'>Ce qui rend Bacastages si unique :</p>
            </div>
            <div className='grid lg:grid-cols-4 sm:grid-cols-2 gap-y-16 mt-24 justify-between gap-8'>
                <div className='flex flex-col min-w-[250px] gap-4'>
                    <OcticonGear24 />
                    <h5 className=' font-medium text-xl'>Configuration simplifié</h5>
                    <p>Configurez votre établissement en un clin d'œil, sans importer de données. Une mise en place en seulement 2 minutes.</p>
                </div>
                <div className='flex flex-col min-w-[250px] gap-4'>
                    <MdiPaperOutline />
                    <h5 className=' font-medium text-xl'>Conventions automatisées</h5>
                    <p>Créez et envoyez automatiquement vos conventions. Personnalisez-les avec votre logo et pré-signez-les selon vos besoins.</p>
                </div>
                <div className='flex flex-col min-w-[250px] gap-4'>
                    <ClarityNotificationLine />
                    <h5 className=' font-medium text-xl'>Notifications par email</h5>
                    <p>Restez informé en temps réel sur les activités de votre établissement : inscriptions, absences, annulations, et bien plus encore.</p>
                </div>
                <div className='flex flex-col min-w-[250px] gap-4'>
                    <QuillCalendar />
                    <h5 className=' font-medium text-xl'>Vue calendrier</h5>
                    <p>Ayez une vision d'ensemble de votre année grâce à notre vue Calendrier intuitive et complète.</p>
                </div>
            </div>
            <div className='grid lg:grid-cols-4 sm:grid-cols-2 gap-y-16 mt-16 gap-8'>
                <div className='flex flex-col min-w-[250px] gap-4'>
                    <LucideLink />
                    <h5 className=' font-medium text-xl'>Professeur & Compte rendu</h5>
                    <p>Dites adieu aux problèmes de mots de passe ! Les professeurs reçoivent un lien par email pour remplir facilement leurs appréciations.</p>
                </div>
                <div className='flex flex-col min-w-[250px] gap-4'>
                    <PhExport />
                    <h5 className=' font-medium text-xl'>Export des mini-stages</h5>
                    <p>Partagez les détails des mini-stages à venir avec vos collègues en un seul clic. Choisissez vos dates et téléchargez la liste instantanément.</p>
                </div>
                <div className='flex flex-col min-w-[250px] gap-4'>
                    <NimbusStats />
                    <h5 className=' font-medium text-xl'>Onglet Statistiques</h5>
                    <p>Surveillez vos données de manière dynamique avec notre tableau de bord statistique en temps réel.</p>
                </div>
                <div className='flex flex-col min-w-[250px] gap-4'>
                    <IonEyeOutline />
                    <h5 className=' font-medium text-xl'>Vision globale</h5>
                    <p>Gardez un œil sur toutes les activités de votre établissement grâce à notre interface administrative complète et conviviale.</p>
                </div>
            </div>
        </div>
    );
}

export default Features;