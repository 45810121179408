import React from 'react';

const Stats = () => {
    return (
        <div className='container mx-auto flex flex-col items-center gap-16 py-16'>
            <div>
                <h2 className='lg:text-5xl sm:text-4xl text-3xl font-semibold text-center mb-8'>Statistiques</h2>
                <p className='lg:text-xl text-lg font-medium'>Bacastages en 2024 c'est :</p>
            </div>
            <div className='grid md:grid-cols-3 md:w-fit md:m-0 px-4 w-full gap-8'>
                <div className='md:w-[300px] w-full flex flex-col items-center gap-8 p-8 shadow-lg rounded-2xl hover:scale-105 duration-300'>
                    <p>Plus de</p>
                    <h3 className='text-6xl font-semibold'>800</h3>
                    <p>mini-stages inscrits</p>
                </div>
                <div className='md:w-[300px] flex flex-col items-center gap-8 p-8 shadow-lg rounded-2xl hover:scale-105 duration-300'>
                    <p>Plus de</p>
                    <h3 className='text-6xl font-semibold'>190</h3>
                    <p>établissements actifs</p>
                </div>
                <div className='md:w-[300px] flex flex-col items-center gap-8 p-8 shadow-lg rounded-2xl hover:scale-105 duration-300'>
                    <p>Plus de</p>
                    <h3 className='text-6xl font-semibold'>1800</h3>
                    <p>annonces publiées</p>
                </div>
            </div>
        </div>
    );
}

export default Stats;
