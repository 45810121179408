import React from 'react';

const AboutPhotosId = () => {
    return (
        <div className='container mx-auto px-4 pt-4'>
            <p>Images numérotées dans l'ordre d'apparition</p>
            <p><a className='underline' href="https://unsplash.com/photos/man-looking-up-holding-his-hair-0aX51h4WvAk">Image 1 : Photo by Valentin Salja on Unsplash</a></p>
            <p><a className='underline' href="https://unsplash.com/photos/person-using-macbook-pro-npxXWgQ33ZQ">Image 2 : Photo by Glenn Carstens-Peters on Unsplash</a></p>
            <p><a className='underline' href="https://unsplash.com/photos/grayscale-photography-of-two-people-raising-their-hands-0CvHQ62gwY8">Image 3 : Photo by Artem Maltsev on Unsplash</a></p>
            <p><a className='underline' href="https://unsplash.com/photos/person-holding-pencil-near-laptop-computer-5fNmWej4tAA">Image 4 : Photo by Scott Graham on Unsplash</a></p>
            <p>Image 5 : Source personnelle</p>
            <p><a className='underline' href="https://unsplash.com/photos/person-holding-hands-of-another-person-EtyBBUByPSQ">Image 6 : Photo by Sincerely Media on Unsplash</a></p>
            <p><a className='underline' href="https://unsplash.com/photos/woman-in-blue-dress-shirt-and-blue-denim-jeans-standing-beside-brown-wooden-chair-tYaccl19A3Q">Image 7 : Photo by Daria Pimkina on Unsplash</a></p>
        </div>
    );
}

export default AboutPhotosId;
