import React from 'react';

const HomePhotosId = () => {
    return (
        <div className='container mx-auto px-4 pt-16'>
            <p>Images numérotées dans l'ordre d'apparition</p>
            <p><a className='underline' href="https://www.freepik.com/free-photo/remote-job-technology-people-concept-happy-smiling-young-business-woman-with-laptop-computer-papers-working-home-office_27157337.htm#fromView=search&page=1&position=32&uuid=1531bc8d-16bc-4116-ba85-5386fa9616bb">Image 1 : Image by stefamerpik on Freepik</a></p>
            <p><a className='underline' href="https://unsplash.com/photos/people-sitting-on-chair-with-brown-wooden-table-mlVbMbxfWI4?utm_content=creditShareLink&utm_medium=referral&utm_source=unsplash">Image 2 : Photo by Luis Villasmil on Unsplash</a></p>
        </div>
    );
}

export default HomePhotosId;
