import React from 'react';
import compas from '../media/compas.webp';
import brush from '../media/brush.webp';

const MissionAndVision = () => {
    return (
        <div className='container mx-auto px-4 lg:pt-32 sm:py-16 pt-16 pb-6'>
            <div className='grid lg:grid-cols-3 items-center gap-16'>
                <div className='flex flex-col gap-8'>
                    <h2 className='lg:text-5xl sm:text-4xl text-3xl font-semibold lg:pb-8 sm:pb-4'>Notre mission & vision</h2>
                    <p className='lg:text-xl text-lg font-medium'>Bacastages est bien plus qu’une application, c’est une révolution.</p>
                </div>
                <div className='px-8 pt-2 pb-10 rounded-2xl shadow-lg'>
                    <img className='lg:w-full sm:w-96 w-full mx-auto' src={compas} alt="compass 3d icon" />
                    <h3 className='font-medium lg:text-4xl sm:text-3xl text-2xl pb-4'>Notre mission</h3>
                    <p className='sm:text-xl text-lg'>Proposer une orientation plus efficace vers les voies professionnelles. </p>
                </div>
                <div className='h-full px-8 pt-2 pb-10 rounded-2xl shadow-lg'>
                    <img className='lg:w-full sm:w-96 w-full mx-auto' src={brush} alt="compass 3d icon" />
                    <h3 className='font-medium lg:text-4xl sm:text-3xl text-2xl pb-4'>Notre vision</h3>
                    <p className='sm:text-xl text-lg'>Nous voulons être l’entreprise qui fait changer d’avis sur les voies professionnelles.</p>
                </div>
            </div>
        </div>
    );
}

export default MissionAndVision;
