import React from 'react';
import LargeButton from './largeButton';
import texts from '../media/texts.webp'
const ContactCta = () => {
    return (
        <div className='w-full bg-gradient-to-b from-white via-[#f3f6ff] to-[#c1d4ff] py-24'>
            <div className='container mx-auto px-4 grid md:grid-cols-2'>
                <div className='flex flex-col gap-8 justify-center items-start md:mt-0 mt-4'>
                    <h2 className='lg:text-6xl sm:text-5xl text-4xl font-bold'>Vous voulez en savoir plus ?</h2>
                    <p className='text-xl font-medium'>Nous sommes là pour répondre à toutes vos questions !</p>
                    <LargeButton
                        path={'/contact'}
                        text={"Contactez-nous"}
                    />
                </div>
                <img className='w-[400px] mx-auto' src={texts} alt="3d computer icon"/>
            </div>
        </div>
    );
}

export default ContactCta;
