import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../media/logo.svg'
import IconamoonArrowTopRight1 from './icons/arrowTopRight';

const Navbar = () => {

    const [nav, setNav] = useState(false);
    
    const handleNav = () => {
        console.log('1')
        setNav(!nav);
    };
    
    const goToLiveSite = () => {
        const url = 'https://bacastages.fr/';
        const win = window.open(url, '_blank');
        win.focus();
    };

    return (
        <div className='container flex justify-between items-center h-24 mx-auto px-4'>
            <Link to={'/'}>
                <img className='w-[200px] ml-[-4px] ' src={logo} alt="Bacastages logo" />
            </Link>
            <ul className='hidden md:flex'>
                <li className='font-medium text-lg p-4'>
                    <Link to={'/demo'}>Démo</Link>
                </li>
                <li className='font-medium text-lg p-4'>
                    <Link to={'/devis'}>Devis</Link>
                </li>
                <li className='font-medium text-lg p-4'>
                    <Link to={'/about'}>À propos</Link>
                </li>
                <li className='font-medium text-lg p-4'>
                    <Link to={'/contact'}>Contact</Link>
                </li>
                <li className='flex flex-row font-medium justify-center items-center text-lg text-[#0715FC] px-4 py-2 ml-4 my-auto border-2 border-[#0715FC] rounded-lg cursor-pointer' onClick={goToLiveSite}>
                    Accéder à Bacastages
                    <IconamoonArrowTopRight1 />
                </li>
            </ul>
            <div onClick={handleNav} className='block md:hidden'>
                { !nav ? <i className='material-icons'>menu</i> : <i className='material-icons'>close</i> }
            </div>
            <div className={ nav ? 'fixed left-0 top-0 w-[60%] h-full bg-[#0751FC] ease-in-out duration-500 z-10' : 'fixed left-[-100%]'}>
                <Link to={'/'}>
                    <img className='w-[250px] p-8' src={logo} alt="Bacastages logo" />
                </Link>
                <ul className='uppercase p-4'>
                    <li className='font-medium text-white text-lg p-4'><Link onClick={handleNav} to={'/'}>Accueil</Link></li>
                    <li className='font-medium text-white text-lg p-4'><Link onClick={handleNav} to={'/demo'}>Démo</Link></li>
                    <li className='font-medium text-white text-lg p-4'><Link onClick={handleNav} to={'/devis'}>Devis</Link></li>
                    <li className='font-medium text-white text-lg p-4'><Link onClick={handleNav} to={'/about'}>À propos</Link></li>
                    <li className='font-medium text-white text-lg p-4'><Link onClick={handleNav} to={'/contact'}>Contact</Link></li>
                    <li className='font-medium text-[#0751FC] text-lg p-4 bg-white rounded-xl flex flex-wrap justify-between'>
                        <Link onClick={goToLiveSite}>Accéder à Bacastages</Link>
                        <IconamoonArrowTopRight1 />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
