import React from 'react';
import { Link } from 'react-router-dom';

const MediumButton = ({ text, path, customCss }) => {
    return (
        <Link to={path} className={`bg-[#0751FC] text-white w-fit font-medium text-lg rounded-lg  px-6 py-3 ${customCss}`}>
            {text}
        </Link>
    );
}

export default MediumButton;
