import React from 'react';
import FormDemo from '../Components/formDemo';

const Demo = () => {
    return (
        <>
            <FormDemo />
        </>
    );
}

export default Demo;
