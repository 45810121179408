import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import sheet from '../media/sheet.webp';
import MaterialSymbolsSearch from '../Components/icons/search';
import TeenyiconsDownSolid from '../Components/icons/downSolid';
import ClassiqueCard from './cards/classiqueCard';
import PlusCard from './cards/plusCard';
import MaxiCard from './cards/maxiCard';
import ky from 'ky';
import Popup from '../Components/popup';
import Loader from '../Components/loader';

const FormDevis = () => {

    const [selectedFormule, setSelectedFormule] = useState('');
    const [error, setError] = useState(null);
    const [validateCondition, setValidateCondition] = useState(false);
    const [messageSentPopup, setMessageSentPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        schoolUai: '',
        formule: '',
    });

    const validationMessage = {
        name: "Veuillez saisir votre nom.",
        email: "Veuillez saisir votre adresse e-mail.",
        schoolUai: "Veuillez saisir le code UAI de votre établissement.",
        formule: "Veuillez choisir une formule."
    };

    const handleChangeFormule = (event) => {
        setSelectedFormule(event.target.value);
        setFormData({
            ...formData,
            formule: event.target.value,
        });
        setError(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
        setError(null)
    };

    const handleCheckboxChange = (event) => {
        setValidateCondition(event.target.checked);
    };

    const handleServerError = async (error) => {
        if (error.response) {
          try {
            const errorData = await error.response.json();
            const errorMessage = errorData.message;
            setError(errorMessage);
          } catch (jsonError) {
            console.error('Impossible de parser la réponse d\'erreur en JSON:', jsonError);
            setError('Une erreur s\'est produite. Veuillez réessayer plus tard.');
          }
        } else {
          setError('Une erreur s\'est produite. Veuillez réessayer plus tard.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        for (const field in formData) {
            if (!formData[field].trim()) {
                setError(validationMessage[field]);
                return;
            }
        };

        if (!validateCondition) {
            setError("Pour soumettre votre demande, veuillez d'abord accepter nos conditions d'utilisation. Merci de votre compréhension !")
            return;
        };

        try {
            let url;
            if (process.env.NODE_ENV === 'production'){
                url = "https://api.lussolva.fr"
            } else {
                url = 'http://localhost:4500'
            };
            
            const res = await ky.post(`${url}/email/devis`, {json: formData}).json();
            setIsLoading(true);
            if(res) {
                setIsLoading(false)
                setMessageSentPopup(true);
            };

            setFormData({
                name: '',
                email: '',
                schoolUai: '',
                formule: '',
            });
            setSelectedFormule('');
            setValidateCondition(false);
            setError(null);

            return;

        } catch (error) {
            setIsLoading(false);
            handleServerError(error);
        }
    };

    const handleClosePopup = () => {
        setMessageSentPopup(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setMessageSentPopup(false)
        }, 10000);

    }, [messageSentPopup])
    
    return (
        <>
            { messageSentPopup &&
                <Popup 
                    isOpen={messageSentPopup}
                    message={`Votre demande de devis a été envoyé avec succès !`}
                    closePopup={handleClosePopup}
                />
            }
            <div className='container lg:h-[80vh] mx-auto px-4'>
                <div className='grid lg:grid-cols-8 lg:gap-12 lg:h-full lg:pb-64'>
                    <form onSubmit={handleSubmit} className='flex flex-col col-span-5 justify-between w-full'>
                        <h1 className='lg:text-6xl sm:text-5xl text-4xl font-bold lg:pt-16 pt-4'>Demandez mon devis</h1>
                        <p className='md:text-2xl text-xl font-medium lg:mt-8 mt-4'>Remplissez le formulaire, et recevez votre devis dans les 24 heures.</p>
                        <div className='flex flex-col lg:gap-12 sm:gap-10 gap-8 lg:mt-16 mt-8'>
                            <div className='flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-8'>
                                <input value={formData.name} onChange={handleChange} name='name' type="text" className='w-full lg:text-2xl text-xl px-2 border-b-2 pb-2 border-black focus:border-[#0751FC] outline-none' placeholder='Nom' />
                                <input value={formData.email} onChange={handleChange} name='email' type="email" className='w-full lg:text-2xl text-xl px-2 border-b-2 pb-2 border-black focus:border-[#0751FC] outline-none' placeholder='exemple@this.com' />
                            </div>
                            <div className='flex lg:flex-row flex-col lg:gap-8 gap-12'>
                            <div className='relative w-full'>
                                <input value={formData.schoolUai} onChange={handleChange} name='schoolUai' type="text" className='w-full lg:text-2xl text-xl outline-none px-2 pb-2 border-b-2 border-black focus:border-[#0751FC]' placeholder='Code UAI' />
                                <div className='absolute top-0 right-0 flex items-center pr-2 text-[#9ba3af]'>
                                    <MaterialSymbolsSearch />
                                </div>
                            </div>
                            <div className='relative w-full'>
                                <div>
                                    <select className={`appearance-none w-full lg:text-2xl text-xl outline-none px-2 pb-2 border-b-2 border-black focus:border-[#0751FC] ${selectedFormule === '' ? 'text-[#9ba3af]' : ""}`} value={selectedFormule} onChange={handleChangeFormule}>
                                        <option value="">Formule</option>
                                        <option value="product1">Classique</option>
                                        <option value="product2">Plus</option>
                                        <option value="product3">Maxi</option>
                                    </select>
                                </div>
                                <div className='absolute top-2 right-0 flex items-center pr-2'>
                                    <TeenyiconsDownSolid />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='flex flex-row pt-4'>
                            <input type="checkbox" onChange={handleCheckboxChange} checked={validateCondition} />
                            <p className='pl-2'>J'ai lu et j'accepte les
                                <Link to={"/privacy"} className='text-[#0751FC] underline'> conditions</Link>
                            </p>
                        </div>
                        { error &&
                            <p className='text-xl pt-2 text-red-500'>{error}</p>
                        }
                        { isLoading ?
                            <div className='h-[60px] px-8 py-4 mt-8'>
                                <Loader />
                            </div> 
                        :
                            <button type='submit' className={`bg-[#0751FC] text-white w-fit font-medium text-xl rounded-lg  px-8 py-4 mt-8`} >Envoyer</button>
                        }
                    </form>
                    <div className='col-span-3 md:h-full hidden md:block mt-16'>
                        { !selectedFormule &&
                            <img className='p-8 transform scale-x-[-1] -rotate-12' src={sheet} alt="" />
                        }
                        {selectedFormule === "product1" && <ClassiqueCard isDevis={true} />}
                        {selectedFormule === "product2" && <PlusCard isDevis={true} />}
                        {selectedFormule === "product3" && <MaxiCard isDevis={true} />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormDevis;
