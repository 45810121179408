import React from 'react';
import FormDevis from '../Components/formDevis';
import Benefits from '../Components/benefits';
import Features from '../Components/features';
import Testimonial from '../Components/testimonial';
import Pricing from '../Components/pricing';
import Cta from '../Components/cta';

const Devis = () => {
    return (
        <>
            <FormDevis />
            <Benefits />
            <Features />
            <Testimonial />
            <Pricing />
            <Cta />
        </>
    );
}

export default Devis;
