import React from 'react';
import HeroHeader from '../Components/heroHeader';
import Pricing from '../Components/pricing';
import Benefits from '../Components/benefits';
import Problem from '../Components/problem';
import Features from '../Components/features';
import Stats from '../Components/stats';
import Testimonial from '../Components/testimonial';
import Cta from '../Components/cta';
import Faq from '../Components/faq';
import HomePhotosId from '../Components/homePhotosId';


const Home = () => {
    return (
        <div>
            <HeroHeader />
            <Problem />
            <Benefits />
            <Features />
            <Stats />
            <Testimonial />
            <Pricing />
            <Cta />
            <Faq />
            <HomePhotosId />
        </div>
    );
}

export default Home;
