import React from 'react';
import mac from '../media/mac.webp'
import LargeButton from './largeButton';

const Cta = () => {
    return (
        <div className='w-full bg-gradient-to-b from-white via-[#f3f6ff] to-[#c1d4ff] py-24'>
            <div className='container mx-auto px-4 grid md:grid-cols-2'>
                <img className='w-[350px] mx-auto' src={mac} alt="3d computer icon"/>
                <div className='flex flex-col gap-8 justify-center md:mt-0 mt-4'>
                    <h2 className='lg:text-6xl sm:text-5xl text-4xl font-bold'>Découvrez par vous-même !</h2>
                    <p className='text-xl font-medium'>Commencez gratuitement et explorez toutes les fonctionnalités disponibles.</p>
                    <LargeButton
                        path={'demo'}
                        text={"Essayer la Démo"}
                    />
                </div>
            </div>
        </div>
    );
}

export default Cta;
