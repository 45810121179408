import React, { useState } from 'react';

const Faq = () => {
    
    const [ activeIndex, setActiveIndex ] = useState(null);

    const questions = [
        {
            question: "Je suis arrivé au maximum de mon nombre de places, je veux changer, que faire ?",
            answer: "Vous avez choisi une formule en début d’année, mais le nombre de places est insuffisant ? Contactez-nous, et nous vous changerons votre formule dans la journée. Vous règlerez simplement la nouvelle formule. Aucun coup supplémentaire."
        },
        {
            question: "Le devis signé a été envoyé, comment commencer à poster des annonces ?",
            answer: "À la réception de votre devis, nous activerons votre formule, vous serez notifié par email de cette activation. Il vous suffit alors de vous connecter à Bacastages.fr/login, et poster vos annonces."
        },
        {
            question: "Est-ce-que les professeurs doivent aussi se créer un compte ?",
            answer: "Ils peuvent, mais ne sont pas obligés. Les liens pour remplir les appréciations sont envoyés par email. Leurs comptes leur permettent de visualiser les mini-stages à venir."
        }
    ];

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className='container mx-auto px-4 py-16'>
            <div className='w-fit flex flex-col gap-16'>
                <h2 className='w-fit lg:text-5xl sm:text-4xl text-3xl font-semibold my-8'>Questions Fréquemment Posées</h2>
                <div className='flex flex-col gap-12'>
                    {questions.map((question, index) => {
                            return(
                                <div key={index} className="cursor-pointer" onClick={() => handleClick(index)}>
                                    <div className="flex justify-between items-center">
                                        <h3 className="lg:text-2xl sm:text-xl text-xl font-medium">{question.question}</h3>
                                        <svg className={`w-6 h-6 transition duration-300 transform ${activeIndex === index ? 'rotate-45' : ''}`} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M18 12.998h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 0 1 0-2h5v-5a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2"/></svg>
                                    </div>
                                    <div className={`mt-0 transition duration-700 ${activeIndex === index ? 'h-auto mt-5' : 'h-0 overflow-hidden mt-0'}`}>
                                        <p className='text-lg'>{question.answer}</p>
                                    </div>
                            </div>
                            )
                    })}
                </div>
            </div>
        </div>
    );
}

export default Faq;
