import React from 'react';
import linkedinImage from '../media/linkedin.webp';
import emailImage from '../media/email.webp'
import { Link } from 'react-router-dom';
import logo from '../media/logo.svg'

const Footer = () => {
    return (
        <div className='container mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8'>
            <div>
                <Link to={'/'}>
                    <img className='w-[200px] ml-[-4px] ' src={logo} alt="Bacastages logo" />
                </Link>
                <p className='py-4'>
                Vos mini-stages en pilote automatique ! Gagnez du temps et de l'énergie grâce à l'automatisation de vos mini-stages.
                </p>
                <div className='flex md:w-[75%] justify-start my-6 gap-4'>
                    <a href="https://www.linkedin.com/in/romain-saint-jean/" target="_blank" rel="noopener noreferrer">
                        <img className='w-10' src={linkedinImage} alt="LinkedIn logo blue" />
                    </a>
                    <a href="mailto:contact@lussolva.fr">
                        <img className='w-10' src={emailImage} alt="LinkedIn logo blue" />
                    </a>
                </div>
            </div>
            <div className='col-span-2 flex flex-wrap gap-16 lg:justify-end md:justify-around justify-start'>
                <div className=''>
                    <h5 className='font-medium text-gray-400'>Navigation</h5>
                    <ul>
                        <li className='py-2 text-sm'>
                            <Link to={'/'}>Accueil</Link>
                        </li>
                        <li className='py-2 text-sm'>
                            <Link to={'/demo'}>Démo</Link>
                        </li>
                        <li className='py-2 text-sm'>
                            <Link to={'/devis'}>Devis</Link>
                        </li>
                        <li className='py-2 text-sm'>
                            <Link to={'/about'}>À propos</Link>
                        </li>
                        <li className='py-2 text-sm'>
                            <Link to={'/contact'}>Nous contacter</Link>
                        </li>
                    </ul>
                </div>
                <div className=''>
                    <h5 className='font-medium text-gray-400'>Legal</h5>
                    <ul>
                        <li className='py-2 text-sm'>
                            <Link to={'/legal'}>Mentions Légales</Link>
                        </li>
                        <li className='py-2 text-sm'>
                            <Link to={'/privacy'}>Politique de confidentialité</Link>
                        </li>
                        <li className='py-2 text-sm'>
                            <Link to={'/termsofuse'}>Conditions d'utilisations</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Footer;
