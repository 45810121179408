import React from 'react';
import MediumButton from '../mediumButton';

const MaxiCard = ({ isDevis }) => {
    return (
        <div className='w-full h-fit shadow-xl flex flex-col rounded-2xl px-8 py-12 gap-8 hover:scale-105 duration-300'>
            <div className='flex flex-wrap justify-between items-center'>
                <h2 className='lg:text-2xl text-xl font-medium'>Maxi</h2>
                <span className='text-[#0751FC] font-semibold border px-2 border-[#0751FC] rounded-full'>La + populaire</span>
            </div>
            <p className='font-semibold text-5xl'>305 € <span className='text-[#6A6A6A] text-xl'>/ année</span></p>
            { !isDevis &&
                <MediumButton
                path={"/demo"} 
                text={"Essayer la Démo"}
                customCss={`w-full text-center`}
                />
            }
            <div className='flex flex-col gap-5'>
                <p>
                    <svg className='inline-block' xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="m9.55 17.308l-4.97-4.97l.714-.713l4.256 4.256l9.156-9.156l.713.713z"></path></svg>
                    Accès à Bacastages
                </p>
                <p>
                    <svg className='inline-block' xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="m9.55 17.308l-4.97-4.97l.714-.713l4.256 4.256l9.156-9.156l.713.713z"></path></svg>
                    Publication d'annonces
                </p>
                <p>
                    <svg className='inline-block' xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="m9.55 17.308l-4.97-4.97l.714-.713l4.256 4.256l9.156-9.156l.713.713z"></path></svg>
                    Conventions automatisées
                </p>
                <p>
                    <svg className='inline-block' xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="m9.55 17.308l-4.97-4.97l.714-.713l4.256 4.256l9.156-9.156l.713.713z"></path></svg>
                    Nombre de places <span className='font-bold'>illimité</span>
                </p>
                <p>
                    <svg className='inline-block' xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="m9.55 17.308l-4.97-4.97l.714-.713l4.256 4.256l9.156-9.156l.713.713z"></path></svg>
                    Service client prioritaire
                </p>
            </div>
        </div>
    );
}

export default MaxiCard;
