import React from 'react';
import {
  createRoutesFromElements,
  Outlet,
  Route,
} from 'react-router-dom';

import Home from './Pages/Home';
import Demo from './Pages/Demo';
import Contact from './Pages/Contact';
import Devis from './Pages/Devis';
import About from './Pages/About';
import Navbar from './Components/navbar';
import Footer from './Components/footer';
import LegalNotice from './Pages/legalNotice';
import PrivacyPolicy from './Pages/privacyPolicy';
import TermsofUse from './Pages/termsofUse';
import ScrollToTop from './Components/scrollToTop';

const Root = () => {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

const routes = createRoutesFromElements(
  <Route path='/' element={<Root />}>
    <Route index element={<Home />} />
    <Route path='demo' element={<Demo />} />
    <Route path='contact' element={<Contact />} />
    <Route path='devis' element={<Devis />} />
    <Route path='about' element={<About />} />
    <Route path='legal' element={<LegalNotice />} />
    <Route path='privacy' element={<PrivacyPolicy />} />
    <Route path='termsofuse' element={<TermsofUse />} />
  </Route>
);

export default routes;
