import React from 'react';
import tiredPeople from '../media/tiredPeople.webp'

const Problem = () => {
    return (
        <div className='container mx-auto grid md:grid-cols-2 lg:py-16 pt-16 px-4'>
            <div className='md:max-w-[70%] w-full lg:h-full sm:h-[512px] h-full my-auto rounded-3xl overflow-hidden'>
                <img className='h-full w-full object-cover' src={tiredPeople} alt="tired People office" />
            </div>
            <div className='flex flex-col md:my-auto mt-12 gap-12'>
                <h2 className='lg:text-5xl sm:text-4xl text-3xl font-semibold'>
                    Mini-stages : une tâche chronophage
                </h2>
                <div className='text-lg font-medium'>
                    <p className='py-1'>
                        L’organisation des mini-stages est une tâche complexe. 
                    </p>
                    <p className='py-1'>
                        Elle demande du temps et de l’énergie. 
                    </p>
                    <p className='py-1'>
                        Elle peut même être source de stress.
                    </p>
                </div>
                <div>
                    <p className='py-1'>
                        Vous venez de faire une porte ouverte, et les demandes de mini-stages n’arrêtent pas ?
                    </p>
                    <p className='py-1'>
                        Vous ne savez plus ou donnez de la tête ? 
                    </p>
                    <p className='py-1'>
                        Les horaires, les comptes rendus, les conventions ?
                    </p>
                </div>
                <div>
                    <p className='py-1'>
                        Heureusement, il existe des solutions pour être plus serein au sujet de vos mini-stages.
                    </p>
                    <p className='py-1'>
                        Imaginé une application qui s’occupe de tout …
                    </p>
                    <p className='font-medium text-lg py-4'>
                        Bacastages vous permet de gérer vos mini-stages facilement, fini les tâches répétitives.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Problem;
